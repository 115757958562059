import React, { Fragment, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import InputAdornment from '@material-ui/core/InputAdornment'
import Markdown from 'react-markdown'
import { graphql, Link, navigate } from 'gatsby'
import Slider from 'react-slick'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import {formatCurrencyString, useShoppingCart} from '../context'
import FormLabel from "@material-ui/core/FormLabel";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

export default ({ data, pageContext }) => {
  const product = data.gcms.product
  const [state, setState] = useState({
    defaultValues: {},
    product: {
      sku: product.id,
      id: product.id,
      name: product.name,
      image: product.images[0].url,
      price: product.price,
      metadata: {},
      url: `/services/${product.collections[0].slug}/${product.productSlug}`
    },
    quantity: product.quantity[0],
    selectedSize: null,
    sizes: null,
    sizeHeight: 0,
    sizeWidth: 0,
    priceBySize: null,
    subTotal: 0,
    variants: {}
  })
  const {addItem} = useShoppingCart()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
  }

  useEffect(() => {
    const groupVariants = product.variants.reduce((r, a) => {
      r[a.label] = [...r[a.label] || [], a];
      return r;
    }, {});

    let defaultValues = {}
    /*
    Object.entries(product).map(([key, item], index) => {
      if (optionKeys.includes(key)) {
        if (item.length > 0) {
          switch (key) {
            case 'grommets':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'hem':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'materials':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'orientation':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'printedSides':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'polePockets':
              // code block
              defaultValues[key] = item[0].value
              break
            case 'sizes':
              // code block
              defaultValues[key] = item[0].value
              break
            default:
            // code block
          }
        }
      }
    })
    */

    Object.entries(groupVariants).map(([key, item], index) => {
      if (item.length > 0) {
        defaultValues[key] = item[0].value
      }
    })

    let cost = product.price
    const sqFt = groupVariants['Size'].length > 0 ? parseFloat(groupVariants['Size'][0].height_in / 12) * parseFloat(groupVariants['Size'][0].width_in / 12) : 1
    cost = Math.round(sqFt * cost)

    setState({...state, sizeWidth: groupVariants['Size'][0].width_in, sizeHeight: groupVariants['Size'][0].height_in, defaultValues: defaultValues, subTotal: cost, variants: groupVariants})
  }, [])

  useEffect(() => {
    if (Object.keys(state.defaultValues).length > 0) {
      calculateTotal()
    }
  }, [state.defaultValues, state.quantity])

  useEffect(() => {
    if (state.variants['Size']) {
      if (state.variants['Size'][0].image) setState({...state, selectedSize: state.variants['Size'][0].image.url})
    }
  }, [state.defaultValues.orientation])

  const calculateTotal = () => {
    let cost = product.price
    let total = product.price

    if (state.quantity > 10) {
      // discounted_price = original_price - (original_price * discount / 100)
      cost = cost - (cost * 0.1 / 100)
    }

    const size = state.variants['Size'].find(item => item.value === state.defaultValues.Size)
    // const sqFt = parseFloat(size.height_in / 12) * parseFloat(size.width_in / 12)
    const sqFt = parseFloat(state.sizeHeight / 12) * parseFloat(state.sizeWidth / 12)
    total = Math.round(sqFt * cost)

    Object.entries(state.defaultValues).map(([key, value], index) => {
      const item = state.variants[key].find(item => item.value === value)
      if (item) {
        if (item.price) {
          total += item.price
        }
      }
    })

    cost = total
    total *= state.quantity

    setState({...state, cost, subTotal: total})
  }

  const handleSelect = (key, event) => {
    // const item = product[key].find(item => item.value === value)
    setState({...state, defaultValues: {...state.defaultValues, [key]: event.target.value}})
  }

  const handleSizeChange = event => {
    const size = state.variants['Size'].find(item => item.value === event.target.value)
    const sqFt = parseFloat(size.height_in / 12) * parseFloat(size.width_in / 12)
    const cost = sqFt * product.price
    setState({...state, sizeHeight: size.height_in, sizeWidth: size.width_in, defaultValues: {...state.defaultValues, Size: size.value}, selectedSize: size.image ? size.image.url : null, subTotal: cost})
  }

  const handleCustomSizeChange = event => {
    const sizeValue = event.target.name === 'sizeHeight' ? `${state.sizeWidth}" x ${event.target.value}"` : `${event.target.value}" x ${state.sizeHeight}"`
    const sqFt = parseFloat(state.sizeHeight / 12) * parseFloat(state.sizeWidth / 12)
    const cost = sqFt * product.price
    setState({...state, [event.target.name]: event.target.value, defaultValues: {...state.defaultValues, Size: sizeValue}, selectedSize: null, subTotal: cost})
  }

  const handleQuantity = event => {
    setState({...state, quantity: parseInt(event.target.value)})
  }

  const onCustomize = () => {
    const designId = Math.random().toString(36).substr(2, 12)

    let description = ''
    if (state.defaultValues) {
      description = state.defaultValues.sizes ? state.defaultValues.sizes : ''
      description += state.defaultValues.orientation ? ` ${state.defaultValues.orientation}` : ''
    }

    /*
    let metadata = {}
    Object.entries(state.defaultValues).map(([key, value], index) => {
      switch (key) {
        case 'grommets':
          // code block
          metadata = {...metadata, Grommet: value}
          break
        case 'hem':
          // code block
          metadata = {...metadata, Hem: value}
          break
        case 'materials':
          // code block
          metadata = {...metadata, Material: value}
          break
        case 'orientation':
          // code block
          metadata = {...metadata, Orientation: value}
          break
        case 'printedSides':
          // code block
          metadata = {...metadata, ['Printed Side']: value}
          break
        case 'polePockets':
          // code block
          metadata = {...metadata, ['Pole Pocket']: value}
          break
        case 'sizes':
          // code block
          const item = product[key].find(item => item.value === value)
          metadata = {...metadata, Size: value, parcel: item.parcel}
          break
        default:
          // code block
      }
    })
    */

    const multiPropsFilter = (products, filters) => {
      // const filterKeys = Object.keys(filters);
      // const filterKeys = ['Size', 'Shape', 'Drilled Holes'];
      const filterKeys = ['Size'];
      return products.filter(product => {
        return filterKeys.every(key => {
          if (!filters[key]) return true;
          else if (!filters[key].length) return true;
          // Loops again if product[key] is an array (for material attribute).
          // if (Array.isArray(product[key])) {
          //     return product[key].some(keyEle => filters[key].includes(keyEle));
          // }
          if (Array.isArray(product['variants'])) {
            // return product['variants'].some(keyEle => filters[key].includes(keyEle));
            return product['variants'].some(keyEle => {
              return keyEle.label === key && keyEle.value === filters[key]
            });
          }
          return filters[key].includes(product[key]);
        });
      });
    }

    const template = multiPropsFilter(product.templates, state.defaultValues)

    // const item = product.variants['Size'].find(item => item.value === state.defaultValues['Size'])
    let metadata = state.defaultValues
    if (product.parcels.length > 0) {
      metadata = {...metadata, parcel: product.parcels[0]}
    }

    const store = {
      custom: {...state.defaultValues, width: state.sizeWidth * 72, height: state.sizeHeight * 72},
      template: template[0],
      product: {...state.product, description, metadata, price: state.cost},
      quantity: state.quantity,
      totalPrice: state.subTotal
    }
    // window.sessionStorage.setItem(`${designId}`, JSON.stringify(store))
    window.sessionStorage.setItem('designX', JSON.stringify(store))
    // navigate(`/design/tool/${product.productSlug}`)
    // navigate(`/design/tool/${designId}?templateId=rectangle-96x48`)
    navigate(`/design/tool/${designId}`)
  }

  return (
    <Layout>
      <HeaderNavbar/>
      <Container maxWidth='md'>
        <Box marginBottom={5} marginTop={3}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link color='inherit' to='/'>Home</Link>
            <Link color='inherit' to={`/${pageContext.collection.slug}`}>
              {pageContext.collection.name}
            </Link>
            <Typography color='textPrimary'>
              {product.name}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item sm={6} style={{textAlign: 'center'}}>
              <img
                alt={product.name}
                src={product.images[0].url}
                width={'75%'}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography variant={'h4'} gutterBottom>
                {product.name}
              </Typography>
              {product.shortDescription && (
                <Markdown
                  source={product.shortDescription.markdown}
                  escapeHtml={false}
                />
              )}
              {/*product.orientation.length > 0 && (
                <Box marginBottom={3}>
                  <Typography variant='subtitle2' gutterBottom>
                    Orientation
                  </Typography>
                  <Grid container spacing={2}>
                    {product.orientation.map(orientation => (
                      <Grid key={orientation.id} item xs={12} sm={6}>
                        <Button variant='outlined' color={orientation.value === state.defaultValues.orientation ? 'primary' : 'default'} fullWidth onClick={() => handleOrientation('orientation', 'Orientation', orientation)}>{orientation.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )*/}
              {/*
              <Box marginBottom={4}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle2' gutterBottom>
                    Quantity
                  </Typography>
                  <Select
                    labelId='outlined-age-native-simple'
                    id='outlined-age-native-simple-select'
                    value={state.quantity}
                    onChange={handleQuantity}
                    input={<BootstrapInput />}
                  >
                    {product.quantity.map(amount => (
                      <MenuItem key={amount} value={amount}>{amount}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              */}
              <Box marginBottom={3}>
                <Grid container spacing={3}>
                  {product.templates.length > 0 && (
                      <Grid item xs={8}>
                        {state.variants['Size'] && (
                            <FormControl variant='outlined' fullWidth>
                              <Typography variant='subtitle2' gutterBottom>
                                Size (inches)
                              </Typography>
                              <Select
                                  labelId='outlined-age-native-simple'
                                  id='outlined-age-native-simple-select'
                                  value={state.defaultValues.Size}
                                  onChange={handleSizeChange}
                                  input={<BootstrapInput />}
                              >
                                {state.variants['Size'].map(size => (
                                    <MenuItem key={size.value} value={size.value}>{size.value}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        )}
                      </Grid>
                  )}
                  {product.templates.length === 0 && (
                      <Fragment>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2' gutterBottom>Size (inches)</Typography>
                          <FormLabel>
                            <BootstrapInput type='number' name='sizeWidth' value={state.sizeWidth} startAdornment={<InputAdornment position="start">W</InputAdornment>} onChange={handleCustomSizeChange} placeholder='' required />
                          </FormLabel>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2' gutterBottom>&nbsp;</Typography>
                          <FormLabel>
                            <BootstrapInput type='number' name='sizeHeight' value={state.sizeHeight} startAdornment={<InputAdornment position="start">H</InputAdornment>} onChange={handleCustomSizeChange} placeholder='' required />
                          </FormLabel>
                        </Grid>
                      </Fragment>
                  )}
                  <Grid item xs={4}>
                    <Typography variant='subtitle2' gutterBottom>Quantity</Typography>
                    <FormLabel>
                      <BootstrapInput type='number' name='quantity' value={state.quantity} onChange={handleQuantity} placeholder='Quantity' required />
                    </FormLabel>
                  </Grid>
                </Grid>
              </Box>
              {product.templates.length === 0 && state.variants['Shape'] && (
                  <Fragment>
                    {state.variants['Shape'].length > 1 && (
                        <Box marginBottom={3}>
                          <FormControl variant='outlined' fullWidth>
                            <Typography variant='subtitle2' gutterBottom>
                              Shape
                            </Typography>
                            <Select
                                labelId='outlined-age-native-simple'
                                id='outlined-age-native-simple-select'
                                value={state.defaultValues['Shape']}
                                onChange={(e) => handleSelect('Shape', e)}
                                input={<BootstrapInput />}
                            >
                              {state.variants['Shape'].map(variant => (
                                  <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                        </Box>
                    )}
                  </Fragment>
              )}
              {state.variants['Material'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Material
                      </Typography>
                      <Select
                          labelId='outlined-age-native-simple'
                          id='outlined-age-native-simple-select'
                          value={state.defaultValues['Material']}
                          onChange={(e) => handleSelect('Material', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Material'].map(variant => (
                            <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                  </Box>
              )}
              {state.variants['Reinforced Strip'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Thickness
                      </Typography>
                      <Select
                          labelId='outlined-age-native-simple'
                          id='outlined-age-native-simple-select'
                          value={state.defaultValues['Reinforced Strip']}
                          onChange={(e) => handleSelect('Reinforced Strip', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Reinforced Strip'].map(variant => (
                            <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                  </Box>
              )}
              {state.variants['Thickness'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Thickness
                      </Typography>
                      <Select
                          labelId='outlined-age-native-simple'
                          id='outlined-age-native-simple-select'
                          value={state.defaultValues['Thickness']}
                          onChange={(e) => handleSelect('Thickness', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Thickness'].map(variant => (
                            <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                  </Box>
              )}
              {state.variants['Edge'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Edge
                      </Typography>
                      <Select
                          labelId='outlined-age-native-simple'
                          id='outlined-age-native-simple-select'
                          value={state.defaultValues['Edge']}
                          onChange={(e) => handleSelect('Edge', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Edge'].map(variant => (
                            <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                  </Box>
              )}
              {state.variants['Grommets'] && (
                  <Box marginBottom={3}>
                    <FormControl fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Grommet
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Grommets']}
                          onChange={(e) => handleSelect('Grommets', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Grommets'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 50, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Hem'] && (
                  <Box marginBottom={3}>
                    <FormControl fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Hem
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Hem']}
                          onChange={(e) => handleSelect('Hem', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Hem'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Pole Pockets'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Pole Pockets
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          value={state.defaultValues['Pole Pockets']}
                          onChange={e => handleSelect('Pole Pockets', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Pole Pockets'].map(({id, label, value}, index) => (
                            <MenuItem key={index} value={value}>{value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Frame'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Frame
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Frame']}
                          onChange={e => handleSelect('Frame', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Frame'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 65, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Printed Side'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Printed Side
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Printed Side']}
                          onChange={e => handleSelect('Printed Side', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Printed Side'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 65, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Drilled Holes'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Drilled Holes
                      </Typography>
                      <Select
                          labelId='outlined-age-native-simple'
                          id='outlined-age-native-simple-select'
                          value={state.defaultValues['Drilled Holes']}
                          onChange={(e) => handleSelect('Drilled Holes', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Drilled Holes'].map(variant => (
                            <MenuItem key={variant.value} value={variant.value}>{variant.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*
                <FormControl>
                  <Typography variant='subtitle2' gutterBottom>
                    Size
                  </Typography>
                  <Grid container spacing={3}>
                    {state.sizes.map((size, index) => (
                      <Grid key={`${size}-${index}`} id={size} item xs={12} sm={4}>
                        <Button variant='outlined' color={size.label === state.defaultValues.sizes ? 'primary' : 'default'} fullWidth onClick={() => handleSizeChange(size)}>{size.label}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
                */}
                  </Box>
              )}
              {state.variants['Poles'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Poles
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Poles']}
                          onChange={e => handleSelect('Poles', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Poles'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 65, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Base'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Base
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Base']}
                          onChange={e => handleSelect('Base', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Base'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 65, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
              {state.variants['Accessories'] && (
                  <Box marginBottom={3}>
                    <FormControl variant='outlined' fullWidth>
                      <Typography variant='subtitle2' gutterBottom>
                        Accessories
                      </Typography>
                      <Select
                          labelId='exampleSelect'
                          id='exampleSelect-select'
                          defaultValue={state.defaultValues['Accessories']}
                          onChange={(e) => handleSelect('Accessories', e)}
                          input={<BootstrapInput />}
                      >
                        {state.variants['Accessories'].map(({id, label, value, image}, index) => (
                            <MenuItem key={index} value={value}>
                              {/*image && (
                          <Box style={{display: 'inline-block', height: 65, marginRight: 10, width: 50, verticalAlign: 'middle'}}>
                            <img src={image.url} width={'100%'} />
                          </Box>
                        )*/}
                              <Typography component={'span'}>{value}</Typography>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>&nbsp;</Grid>
            <Grid item xs={12} sm={6}>
              <Box alignItems='center' justifyContent='space-between' display='flex'>
                <Box alignItems='center' justifyContent='space-around' display='flex'>
                  <Typography variant={'subtitle2'} style={{fontSize: 16, marginRight: 16}}>
                    Printing Cost:
                  </Typography>
                  <div>
                    <Typography variant={'h5'} style={{color: '#01b901'}}>
                      {formatCurrencyString({
                        value: state.subTotal,
                        currency: 'USD',
                        language: 'en-US'
                      })}
                    </Typography>
                    <Typography>(
                      {formatCurrencyString({
                        value: state.cost,
                        currency: 'USD',
                        language: 'en-US'
                      })} for each)
                    </Typography>
                  </div>
                </Box>
                {/*
                  <Button
                    variant='contained'
                    color='primary'
                    className='snipcart-add-item'
                    data-item-id={product.id}
                    data-item-price={subTotal}
                    data-item-url={`/services/${product.collections[0].slug}/${product.productSlug}`}
                    data-item-image={product.images[0].url}
                    data-item-name={product.name}
                  >
                    Add To Cart
                  </Button>
                  */}
                <Button variant='contained' color='primary' disabled={state.quantity === '' || state.quantity === '0'} onClick={onCustomize}>
                  Customize
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {product.accessories.length > 0 && (
          <Box marginBottom={8}>
            <Typography variant={'h4'} gutterBottom>Accessories (Optional)</Typography>
            <Slider {...settings}>
              {product.accessories.map((accessory, index) => (
                <Fragment key={index}>
                  {accessory.images.length > 0 && (
                    <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                      <img src={accessory.images[0].url} width={'80%'} alt={accessory.name} />
                    </Box>
                  )}
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Typography variant='subtitle1' align='center'>
                      {accessory.name}
                    </Typography>
                  </Box>
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Typography variant={'h6'} style={{marginLeft: 16, marginRight: 16}}>
                      {formatCurrencyString({
                        value: accessory.price,
                        currency: 'USD',
                        language: 'en-US'
                      })}
                    </Typography>
                  </Box>
                  <Box marginBottom={2} display={'flex'} alignItems='center' justifyContent='center'>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => addItem({
                        sku: accessory.id,
                        name: accessory.name,
                        image: accessory.images[0].url,
                        price: accessory.price,
                        currency: 'USD'}
                      )}>
                      Add to Cart
                    </Button>
                  </Box>
                </Fragment>
              ))}
            </Slider>
          </Box>
        )}
        {product.description && (
          <Box marginBottom={8}>
            <Markdown
              source={product.description.markdown}
              escapeHtml={false}
            />
          </Box>
        )}
        {product.sections && (
          <Grid container spacing={3}>
            {product.sections.map(({id, headline, description, relations}, index) => (
              <Box key={index} marginBottom={8}>
                <Grid item xs={12}>
                  <Box marginBottom={5}>
                    <Typography variant='h4'>
                      {headline}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {description.markdown}
                    </Typography>
                  </Box>
                  <Box>
                    {relations.map(({id, title, details, layout}, index) => (
                      <Grid key={index} container spacing={3}>
                        {title && (
                          <Grid item xs={12}>
                            <Typography variant='h6'>
                              {title}
                            </Typography>
                          </Grid>
                        )}
                        {details.map(({id, title, shortDescription, image, benefits, category}, index) => (
                          <Fragment key={index}>
                            {layout !== 'Rounded_Image_Tile_Horizontal' && (
                              <Grid item sm={12 / details.length}>
                                <Box marginBottom={2}>
                                  <img src={image.url} width={'100%'} alt={title} />
                                </Box>
                                <Box marginBottom={3}>
                                  <Typography variant='h6' align='center' gutterBottom>
                                    {title}
                                  </Typography>
                                  {benefits.length === 0 && (
                                    <Typography variant='body1' align='center' gutterBottom>
                                      {shortDescription}
                                    </Typography>
                                  )}
                                  {benefits.length > 0 && (
                                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                                      <ul>
                                        {benefits.map(b => (
                                          <li key={b}>{b}</li>
                                        ))}
                                      </ul>
                                    </Box>
                                  )}
                                </Box>
                                {category && (
                                  <Box>
                                    <Typography variant='subtitle1' align='center'>
                                      <Box fontWeight='fontWeightBold'>
                                        Best For:
                                      </Box>
                                    </Typography>
                                    <Typography variant='body2' align='center'>
                                      {category.bestFor}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            )}
                            {layout === 'Rounded_Image_Tile_Horizontal' && (
                              <Grid item sm={12 / details.length}>
                                <Box marginBottom={5} style={{display: 'flex', alignItems: 'center'}}>
                                  <img src={image.url} style={{marginLeft: 10, marginRight: 10, width: '33.33%'}} alt={title} />
                                  <Box>
                                    <Typography variant='h6' gutterBottom>
                                      {title}
                                    </Typography>
                                    {benefits.length === 0 && (
                                      <Typography variant='body1' gutterBottom>
                                        {shortDescription}
                                      </Typography>
                                    )}
                                  </Box>
                                  {benefits.length > 0 && (
                                    <Box style={{display: 'flex', justifyContent: 'center'}}>
                                      <ul>
                                        {benefits.map(b => (
                                          <li key={b}>{b}</li>
                                        ))}
                                      </ul>
                                    </Box>
                                  )}
                                </Box>
                                {category && (
                                  <Box>
                                    <Typography variant='subtitle1' align='center'>
                                      <Box fontWeight='fontWeightBold'>
                                        Best For:
                                      </Box>
                                    </Typography>
                                    <Typography variant='body2' align='center'>
                                      {category.bestFor}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Box>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($productSlug: String) {
    gcms {
      product(where: {productSlug: $productSlug}, stage: DRAFT) {
        id
        name
        shortDescription {
          markdown
        }
        price
        description {
          markdown
        }
        images {
          url
        }
        detail {
          benefits
        }
        productSlug
        category {
          id
          name
          options {
            label
            value
            height_in
            toolTip
            width_in
            price
          }
        }
        customize
        quantity
        collections {
          slug
        }
        accessories {
          name
          images {
            url
          }
          price
          collections {
            slug
          }
          quantity
        }
        variants {
          id
          label
          value
          image {
            url
          }
          height_in
          width_in
          price
          parcel {
            length
            width
            height
            weight
            distance_unit
            mass_unit
          }
        }
        sections {
          id
          headline
          description {
            markdown
          }
          relations {
            __typename
            ... on GCMS_Advise {
              id
              title
              description {
                markdown
              }
              details {
                title
                shortDescription
                image {
                  url
                }
                benefits
                category {
                  bestFor
                }
              }
              layout
            }
          }
        }
        variants {
          label
          value
          height_in
          width_in
          price
          image {
            url
          }
        }
        templates {
          id
          canvasPath
          widthPx
          heightPx
          variants {
            label
            value
            height_in
            width_in
            price
            image {
              url
            }
          }
        }
        parcels {
          length
          width
          height
          weight
          distance_unit
          mass_unit
        }
      }
    }
  }
`
